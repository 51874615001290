import * as React from "react";
// @ts-ignore
import Popup from "../Popup";
import { FC } from "react";
import { injectIntl } from "gatsby-plugin-intl";
import CrossButton from "../../images/cross_button.svg";
import Check from "../../images/check.png";

const ResultSuccess: FC<{
  isOpen: boolean;
  onClose: () => void;
  intl: any;
  text: string;
  closeForm?: () => void;
}> = ({ isOpen, onClose, intl, text, closeForm }) => {
  const handleOnClick = () => {
    onClose();
    if (closeForm) {
      closeForm();
    }
  };

  return (
    <Popup
      intl={intl}
      isOpen={isOpen}
      onClose={onClose}
      isModalContentShown={isOpen}
    >
      <div className="relative flex flex-col justify-center items-center text-center sm:w-[25rem] h-[12rem] border rounded-[1.25rem] bg-white">
        <img
          className="absolute top-0 right-0 w-[18px] h-[18px] m-4"
          src={CrossButton}
          alt="Close button"
          onClick={handleOnClick}
        />
        <div className="flex items-center m-5">
          <img
            className="w-[30px] h-[30px] bg-opacity-80 mr-3"
            src={Check}
            alt="Check button"
            onClick={onClose}
          />
          <p className="text-xl max-w-52">
            {intl.formatMessage({
              id: text,
            })}
          </p>
        </div>
      </div>
    </Popup>
  );
};

export default injectIntl(ResultSuccess);
