import * as React from "react";
//@ts-ignore
import Select from "../../images/select.svg";
import { injectIntl } from "gatsby-plugin-intl";
import { orderHomeInputs } from "../../utils/orderHomeObjects";
import OrderHomeInput from "./OrderHomeInput";

import parse from "html-react-parser";
import { useState } from "react";
import { useForm } from "react-hook-form";
import ResultSuccess from "../popups/ResultSuccess";
// @ts-ignore
const OrderHome = ({ intl }) => {
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [disableButton, setDisableButton] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = () => {
    setShowSuccessPopup(true);
  };

  return (
    <div className="flex flex-col justify-center items-center text-center mb-8 ">
      <p className="lg:text-4xl md:text-2xl sm:text-[1.4rem] font-['gabdugi'] text-[#DA6A00] font-semibold  xs:mb-4 md:font-bold ">
        {intl.formatMessage({ id: "ServiceMenu.OrderHome.OrderHomeHeader" })}
      </p>
      <div className="mb-3 w-full px-3">
        <div className=" flex flex-col relative border rounded-lg text-[#DA6A00] text-bold justify-start items-start border-[#DA6A00]">
          <img className="absolute right-4 top-4" src={Select} alt="" />
          <div className="pl-3 my-2  cursor-pointer font-['gabdugi'] text-xl font-bold">
            {intl.formatMessage({
              id: "ServiceMenu.OrderHome.OrderHomeReasonHeader",
            })}
          </div>
          <div className="pl-3 my-2  cursor-pointer font-['gabdugi'] text-xl">
            {intl.formatMessage({
              id: "ServiceMenu.OrderHome.OrderHomeReasonText",
            })}
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ul className="flex flex-col justify-center">
          {orderHomeInputs.map((order) => (
            <OrderHomeInput
              title={`ApplicationForm.${order.title}`}
              register={register}
              error={errors[order.name]}
              name={order.name}
            />
          ))}
          <div className="flex flex-row justify-between items-center xl:w-96  mx-0">
            <div className="mb-3 xl:w-54">
              <label
                htmlFor="exampleFormControlInput1"
                className="flex form-label inline-block mb-2 font-['gabdugi'] text-2xl justify-start"
              >
                {intl.formatMessage({ id: "ApplicationForm.Date" })}
              </label>
              <input
                type="text"
                {...register("date", { required: `Поле не может быть пустым` })}
                className="form-control block xs:w-[7rem] sm:w-40 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-[#DA6A00]
                            rounded transition ease-in-out  m-0 focus:text-gray-700 focus:bg-white focus:border-[#DA6A00] focus:outline-none"
                id="exampleFormControlInput1"
              />
              {errors.date && (
                <p className="text-[14px] text-left text-red-600">
                  Поле не может быть пустым
                </p>
              )}
            </div>
            <div className="mb-3 xl:w-54">
              <label
                htmlFor="exampleFormControlInput1"
                className="flex form-label inline-block mb-2 font-['gabdugi'] text-2xl justify-start"
              >
                {intl.formatMessage({ id: "ApplicationForm.Time" })}
              </label>
              <input
                {...register("time", { required: `Поле не может быть пустым` })}
                type="text"
                className="form-control block  xs:w-[7rem] sm:w-40 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-[#DA6A00]
                            rounded transition ease-in-out  m-0 focus:text-gray-700 focus:bg-white focus:border-[#DA6A00] focus:outline-none"
                id="exampleFormControlInput1"
              />
              {errors.time && (
                <p className="text-[14px] text-left text-red-600">
                  Поле не может быть пустым
                </p>
              )}
            </div>
          </div>
          <div className="mb-3 xs:w-64 sm:w-96">
            <label
              htmlFor="exampleFormControlInput1"
              className="flex form-label inline-block mb-2 font-['gabdugi'] text-2xl justify-start"
            >
              Категория анализов
            </label>
            <ul
              className={
                "flex flex-col relative border rounded-lg text-[#DA6A00] text-bold justify-start items-start border-[#DA6A00]"
              }
            >
              <img className="absolute right-4 top-4" src={Select} alt="More" />
              <li className="pl-3 my-2  text-left cursor-pointer font-['gabdugi'] text-sm">
                {parse(
                  intl.formatMessage({
                    id: "ServiceMenu.OrderHome.OrderHomeCategoryAnalysis",
                  })
                )}
              </li>
            </ul>
          </div>
          <div className="mb-3 xs:w-64 sm:w-96">
            <label
              htmlFor="exampleFormControlInput1"
              className="flex form-label inline-block mb-2 font-['gabdugi'] text-2xl justify-start"
            >
              {intl.formatMessage({ id: "Analysis" })}
            </label>
            <ul
              className={
                "flex flex-col relative border rounded-lg text-[#DA6A00] text-bold justify-start items-start border-[#DA6A00]"
              }
            >
              <img className="absolute right-4 top-4" src={Select} alt="More" />
              <li className="pl-3 my-2  text-left cursor-pointer font-['gabdugi'] text-sm">
                {parse(
                  intl.formatMessage({
                    id: "ServiceMenu.AnalysisAndPrices.AllAnalysisSidebarText",
                  })
                )}
              </li>
            </ul>
          </div>
          <div className="flex flex-row justify-start gap-4 items-center xs:w-64 sm:w-96  mb-3">
            <label>
              <input
                type="checkbox"
                className=" xs:w-[1.5rem] xs:h-[1.5rem] sm:w-8 sm:h-8 border border-[#DA6A00] accent-[#DA6A00] rounded-sm focus:outline-none transition duration-200 cursor-pointer"
              />
            </label>
            <label
              htmlFor="exampleFormControlInput1"
              className="flex form-label inline-block mb-2 font-['gabdugi'] xs:text-[0.8rem] sm:text-2xl text-start justify-start items-start text-base"
            >
              {intl.formatMessage({ id: "ApplicationForm.Agreement" })}
            </label>
          </div>
          <div className="flex flex-row justify-between gap-4 items-center xs:w-64 sm:w-96  mb-3">
            <label className="flex form-label inline-block mb-2 font-['gabdugi'] xs:text-[1.2rem] sm:text-2xl justify-start items-start text-2xl">
              {intl.formatMessage({ id: "ApplicationForm.Cost" })}
            </label>
            <label
              htmlFor="exampleFormControlInput1"
              className="flex form-label inline-block mb-2 font-['gabdugi'] xs:text-[1.2rem] sm:text-2xl justify-start items-start text-2xl"
            >
              6 500 ₸
            </label>
          </div>
          <div className="mb-3 xs:w-64 sm:w-96">
            <button
              className="w-full h-12 rounded font-['gabdugi'] font-bold bg-[#DA6A00] text-white text-center text-2xl"
              disabled={disableButton}
            >
              {intl.formatMessage({ id: "ServiceMenu.PCR.Apply" })}
            </button>
          </div>
        </ul>
      </form>
      <ResultSuccess
        onClose={() => setShowSuccessPopup(false)}
        isOpen={showSuccessPopup}
        text="Popup.ResultSuccess.ResultSuccessText"
      />
    </div>
  );
};

export default injectIntl(OrderHome);
