export const orderHomeInputs = [
  {
    title: "FullName",
    name: "full-name",
  },
  {
    title: "IIN",
    name: "iin",
  },
  {
    title: "PhoneNumber",
    name: "phone-number",
  },
  {
    title: "City",
    name: "city",
  },
  {
    title: "Address",
    name: "address",
  },
];
