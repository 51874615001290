import React, { FC } from "react";
import { injectIntl } from "gatsby-plugin-intl";

type OrderHomeInputProps = {
  intl: any;
  title: string;
  register: any;
  error: any;
  name: string;
};

const OrderHomeInput: FC<OrderHomeInputProps> = ({
  intl,
  title,
  register,
  error,
  name,
}) => (
  <li className="mb-3 xs:w-64 sm:w-96">
    <label className="flex form-label inline-block mb-2 font-['gabdugi'] text-2xl justify-start">
      {intl.formatMessage({ id: title })}
    </label>
    <input
      type="text"
      {...register(name, { required: `Поле не может быть пустым` })}
      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-[#DA6A00]
                        rounded transition ease-in-out  m-0 focus:text-gray-700 focus:bg-white focus:border-[#DA6A00] focus:outline-none"
      id="exampleFormControlInput1"
      placeholder=""
    />
    <p className="text-[14px] text-left text-red-600">
      {error && error.message}
    </p>
  </li>
);

export default injectIntl(OrderHomeInput);
